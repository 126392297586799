import './index.css'
import Header from '../../components/Header/index'
import Footer from '../../components/Footer/index'

const UserProtocol = () => {
    return (
        <div className='user-box'>
            <Header />
            <div className='user-content'>
                <div className='justify-center'>
                    <div className='user-protocol-title'></div>
                </div>
                <div>
                    欢迎使用西安标梦科技有限公司提供的标记酱产品以及服务（以下简称“标记酱”）。标记酱是一款方便二次元用户展示、交流和分享的互联网产品，旨在为您提供一种简单随性的工具，并形成一个聚合多元兴趣爱好的社区平台。标记酱汇聚了包括大量coser、摄影师、绘画师、等创作群体，并吸引热爱记录、热爱生活的二次元年轻群体。
                    为了保障您的权益，请在使用服务之前，详细阅读本协议的所有内容，特别是加粗部分。未成年人则应在监护人陪同下阅读。如您使用标记酱，即表示您与标记酱已达成协议，自愿接受本协议的所有内容。
                </div>
                <div>一、协议的确认和接纳</div>
                <div>
                    本协议是您与标记酱之间关于您使用标记酱的条款，本协议明确援引的其他协议及将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，您使用标记酱提供的服务均受本协议约束。
                </div>
                <div>二、关于“账号”</div>
                <div>
                    1、您注册使用标记酱服务账号的信息应当是您的个人真实身份信息，并应保证您在注册当时所提供的个人资料信息（包括身份信息、银行账号、支付宝账号、联系方式、居住地址等）的真实、完整、有效，并且您应当依据法律规定和本协议约定对所提供的信息承担相应的法律责任。
                </div>
                <div>
                    2、标记酱有权审查您注册所提供的信息是否真实、有效，若您提供给标记酱的帐号注册资料不准确，不真实，含有违法或不良信息的，标记酱有权不予注册。对于冒用关联机构或社会名人注册帐号名称的，标记酱有权注销该帐号，并向政府主管部门进行报告。
                </div>
                <div>
                    3、您应妥善保管您的帐号及密码，并正确、安全地使用其帐号及密码，不得将账号赠与、借用、租用、转让或售卖。如果您未保管好自己的账号和密码而给标记酱和他人的民事权利造成损害的，您应当负全部责任。
                </div>
                <div>
                    4、您在注册和使用标记酱账号时应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在注册资料中出现任何违法违规或侵权信息。若您的账号有前述问题的，标记酱有权采取通知限期改正、暂停使用等措施。
                </div>
                <div>
                    如您发现您的帐号或密码被他人非法使用或有使用异常的情况的，应及时根据标记酱公布的处理方式通知标记酱。由于标记酱无法对非法使用您的账号的行为作出甄别，在您未进行投诉或提出帐号申诉等方式明确告知标记酱帐号被他人使用或信息数据泄漏等情况并提供相关证明材料前，标记酱有理由相信该帐号行为是您使用帐号的行为。
                </div>
                <div>
                    三、使用规则
                </div>
                <div>
                    1、您在使用标记酱的过程中，不得利用标记酱制作、上传、复制、传送、传播或转载如下内容：
                </div>
                <div>
                    （1）反对中华人民共和国宪法所确定的基本原则的；
                </div>
                <div>
                    （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                </div>
                <div>
                    （3）损害国家荣誉和利益的；
                </div>
                <div>
                    （4）煽动民族仇恨、民族歧视，破坏民族团结的；
                </div>
                <div>
                    （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
                </div>
                <div>
                    （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
                </div>
                <div>
                    （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                </div>
                <div>
                    （8）侮辱或者诽谤他人，侵害他人合法权益的；
                </div>
                <div>
                    （9）含有中华人民共和国法律、行政法规禁止的其他内容的。
                </div>
                <div>
                    2、您不得利用标记酱平台或服务实施包括但不限于以下行为：
                </div>
                <div>
                    （1）创作、复制、发布、传播法律法规禁止，以及侵犯其他用户或第三方知识产权或其他合法权益的信息和作品；
                </div>
                <div>
                    （2）通过非标记酱开发、授权或认可的第三方兼容软件、系统登录或使用标记酱平台或服务，或针对标记酱平台和相关服务使用非标记酱开发、授权或认证的插件；
                </div>
                <div>
                    （3）删除标记酱网站或软件及其他副本上所有关于版权的信息、内容；
                </div>
                <div>
                    （4）未经标记酱同意，对标记酱网站或软件进行反向工程、反向汇编、反向编译或以其他非法方式自标记酱平台获取源代码等；
                </div>
                <div>
                    （5）对于标记酱网站或软件相关信息等，未经标记酱同意，擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助标记酱发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；
                </div>
                <div>
                    （6）发布破坏标记酱网站或软件的内容，发布指向包含病毒或恶意软件的链接，使用管理员密码或者冒充管理员，重复发布垃圾信息，使用替代账号或外部组织来操控标记酱数据；
                </div>
                <div>
                    （7）其他违反法律法规、政策及公序良俗、社会公德或干扰标记酱正常运营和侵犯其他用户或第三方合法权益内容的行为。
                </div>
                <div>
                    3、在任何情况下，针对您上传的作品 标记酱有权根据作品的相关法律法规及政策形势，不将该作品对用户公布或向用户提供。
                </div>
                <div>
                    4、未经标记酱允许，您不得在标记酱平台内发布、推荐除标记酱、西安标梦科技有限公司及其关联公司产品或服务以外的任何内容的广告或商业性信息。您不得以任何理由或方式拉拢其他用户去其他同类竞争网站开展或使用服务。
                </div>
                <div>
                    5、您不得利用标记酱进行任何形式的洗钱活动。标记酱将严密监控此类行为，并有权对多个定向账户中异常的、数额较大的虚拟资产流转进行调查，并有权配合主管机关的调查提交用户的身份资料、有关账户的交易记录等信息。
                </div>
                <div>
                    6、您理解并承诺，非经标记酱同意，您不能利用标记酱平台或服务进行销售或其他商业用途。如您有需要将标记酱平台或服务用于商业用途，应书面通知标记酱并获得标记酱的明确授权。
                </div>
                <div>
                    四、第三方链接
                </div>
                <div>
                    标记酱可能接入或链接至第三方网站或服务，因该等第三方网站或服务由相关的第三方负责运营，标记酱无法对该等第三方网站或服务进行控制。您使用该等第三方网站或服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。如您发现这些第三方网站或服务存在风险时，建议您终止相关操作以保护您的合法权益，如您因使用或依赖上述第三方网站或服务所导致的损失或损害，标记酱不承担任何责任。
                </div>
                <div>
                    五、知识产权
                </div>
                <div>
                    1、标记酱鼓励并支持分享作品、互相学习，为免争议，本协议所称“作品”包括任何形式的作品（文字、图片、音乐、视频、表演等）、录音录像制品、代码、评论、帖子或用户在标记酱发布的其他内容。
                </div>
                <div>
                    2、标记酱尊重知识产权并注重保护用户享有的各项权利，您在标记酱发布的所有原创作品，知识产权归您本人所有，您可自行授权第三方以任何方式使用而无须取得标记酱的同意。您在发布作品时同意免费授权标记酱对作品进行公开传播、推广等方式的使用。
                </div>
                <div>
                    3、除了您自行创作、发布的作品外，标记酱上所有其他内容包括但不限于产品、软件、音视频、图文资料、界面设计、版面框架、图标、数据等的所有权及知识产权归属于标记酱所有。所以，您只能在标记酱授权下才能使用这些内容，未经标记酱书面同意，您均不得以任何营利或非营利的目的擅自复制、发布、使用、改写上述内容，您也不得将上述内容授权给第三方实施、利用。
                </div>
                <div>
                    六、免责声明
                </div>
                <div>
                    1、您在非登录模式下可能无法使用标记酱，且一旦您退出或关闭页面，或更换终端设备的，您在非登录模式下相关数据可能都将会被清空，且无法查询和恢复。如因此造成您任何损失的，标记酱不承担责任。
                </div>
                <div>
                    2、用户在标记酱中发布的任何内容、信息等并不反映或代表标记酱的观点、立场或政策，标记酱对此不承担任何责任，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。标记酱有权（但无义务）自行拒绝或删除标记酱上的任何内容。您如使用上述内容, 应自行承担风险。
                </div>
                <div>
                    3、若因您自身的原因（包括但不限于违反法律法规的规定、违反本协议内容等）导致标记酱对注册账号采取封停或注销措施的，您将不能继续使用注册帐号，标记酱无须因此对您承担任何形式的赔偿或补偿责任。
                </div>
                <div>
                    4、发生下列情形之一时，标记酱可能需要变更、中断或终止服务器所提供之全部或部分服务，对因此而产生的不便或损害，标记酱无需对您或第三人承担任何责任：
                </div>
                <div>
                    （1）为了更好地为您提供专业、便捷的服务而进行的网站和服务器的定期检查或施工，更新软硬件等；
                </div>
                <div>
                    （2）网络提供商线路或其他故障；
                </div>
                <div>
                    （3）在紧急情况之下依照法律的规定或为您及第三者之人身安全；
                </div>
                <div>
                    （4）执行行政/司法机关的命令；
                </div>
                <div>
                    （5）第三方原因或其他不可抗力的情形。
                </div>
                <div>
                    5、标记酱可能会在提供服务的过程中以各种方式投放商业广告等商业信息，您同意标记酱有权通过向您的邮箱发送电子邮件、向您的账号发送私信或其他方式向您发送前述信息。
                </div>

                <div>
                    七、隐私制度
                </div>
                <div>
                    尊重用户隐私是西安标梦科技有限公司的一项基本政策。西安标梦科技有限公司将按照标记酱平台上公布的《标记酱隐私政策》收集、存储、使用、披露和保护用户的个人信息。请您完整阅读上述隐私政策，以帮助您更好地保护您的个人信息。如您未满十四周岁，请通知您的监护人监护下使用APP，并务必在使用标记酱、提交个人信息之前，寻求他们的同意和指导。
                </div>
                <div>
                    八、违约处理
                </div>
                <div>
                    1、如发生下列任何一种情形：
                </div>
                <div>
                    （1）违反本协议中规定的任何一项规则；
                </div>
                <div>
                    （2）违反标记酱发布及不定时更新的平台规则；
                </div>
                <div>
                    （3）提供的个人资料不真实或存在违法、不良信息；
                </div>
                <div>
                    （4）包含但不仅限于以上所述内容的其他“危害标记酱利益”的行为。
                </div>
                <div>
                    标记酱有权视严重情况，对用户同时采取以下单项或多项措施：
                </div>
                <div>
                    （1）暂停/终止提供全部或部分服务；
                </div>
                <div>
                    （2）删除违规内容；
                </div>
                <div>
                    （3）暂时/永久封禁账号；
                </div>
                <div>
                    （4）扣除、清空部分或全部虚拟财产。
                </div>
                <div>
                    基于可能存在上述情形，标记酱有权根据相关法律法规的要求删除您的个人信息或做匿名化处理，建议您不定期的对您发布的作品进行备份。
                </div>
                <div>
                    2、若标记酱认为您的账号内的虚拟财产（包括但不限于虚拟礼物、虚拟道具、虚拟币等）来源异常，标记酱有权对账号进行暂时冻结并进行调查。如调查后判定存在来源异常情形，则标记酱有权将来源异常部分进行没收，情节严重者将进行账号封禁；来源异常情形包含但不限于：非标记酱认可的渠道充值，利用系统BUG、漏洞或外挂获得，其他非正当方式来源获得等。
                </div>
                <div>
                    3、您违反本协议约定、相关服务条款或平台规范的，导致第三方向标记酱、网易公司及其关联公司、合作公司提出索赔等要求的，您同意承担全部法律责任，包括但不限于赔偿金、罚金、和解费、诉讼仲裁费、合理的律师费支出等，并同意赔偿因此给标记酱造成的损害。具体承担赔偿责任的方式包括但不限于根据标记酱的书面通知向其支付与损失等额的资金、以累计的虚拟资产抵偿相关损失等。
                </div>
                <div>
                    4、标记酱对知识产权尽合理、审慎的义务，当第三方认为您上传或者发表于标记酱的作品侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向标记酱发送权利通知书时，您同意标记酱有权不事先通知您的情况下删除涉嫌侵权的作品。
                </div>
                <div>
                    九、未成年人使用条款
                </div>
                <div>
                    1、若您未满18周岁，应在监护人同意和指导下访问和/或使用标记酱的网络服务，并且您的监护人允许您使用标记酱的网络服务且同意本协议的各项条款。如果您是未成年人的监护人，即表示您同意约束未成年人遵守这些条款使用标记酱的网络服务。特别地，如您未满18周岁或不具备完全民事行为能力，您应在监护人的同意和指导下使用标记酱的充值服务，使用充值服务而产生的一切后果，由您及您的监护人承担。
                </div>
                <div>
                    2、未成年应在监护人的指导下合理安排时间使用标记酱的网络服务，注意避免沉迷于网络，影响正常的学习生活。
                </div>
                <div>
                    十、服务的变更、中断和终止
                </div>
                <div>
                    1、您理解并同意，标记酱为了服务整体运营的需要在不断变更和改进服务。标记酱可能会增加或删除部分服务，也可能暂停或彻底停止本项服务。您同意标记酱有权行使上述权利且不需对您或第三方承担任何责任。
                </div>
                <div>
                    2、标记酱出于自身商业决策等原因，或者根据自身的判断，认为您的行为涉嫌违反本协议内容或相应使用规则等内容，或涉嫌违反法律法规的规定的，则标记酱有权中止、中断或终止向您提供服务。如有此等情形发生，标记酱有权根据相关法律法规的要求删除您的个人信息或做匿名化处理。
                </div>
                <div>
                    3、若因标记酱自身原因决定终止运营服务的，您应采取相应的措施自行处理账号，包括但不限于注销或停止使用帐号等相关事宜。
                </div>
                <div>
                    十一、不可抗力
                </div>
                <div>
                    标记酱对不可抗力导致的损失不承担责任。不可抗力包括：自然灾害、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或互联网相关技术缺陷、突发性的软硬件设备及电子通信设备故障、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
                </div>
                <div>
                    十二、其他约定
                </div>
                <div>
                    1、通知：所有发给您的通知都可通过电子邮件、常规的信件或在网站/客户端显著位置公告的方式进行传送。
                </div>
                <div>
                    2、本协议适用中华人民共和国的法律（不含冲突法）。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关内容将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。
                </div>
                <div>
                    3、凡因本协议引起的或与本协议有关的任何争议，双方应尽量友好协商解决；协商不成的，任何一方均有权向被告住所地人民法院提起诉讼解决。
                </div>
                <div>
                    4、本协议自发布之日起实施，并构成您和标记酱之间的共识。
                </div>
                <div>
                    5、标记酱不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响标记酱在将来行使该权利。
                </div>
                <div>
                    6、如果您对本协议内容有任何疑问，请发送邮件至我们的客服邮箱：biaomeng@biaomengz.com
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UserProtocol;