import './index.css'

const Footer = () => {
    return (
        <div className="footer text-center">
            <div className='footer-company mb-15'>西安标梦科技文化有限公司</div>
            {/* <div className="footer-item mb-15">ICP备案号 ：陕ICP备2024028017号-3A</div> */}
            <div className='mb-15'>
                <a class="footer-item footer-item-a" href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">ICP备案号 ：陕ICP备2024028017号-1</a>
            </div>
            <div className="footer-item mb-15">联系方式：13279413637 联系人：相先生</div>
            <div className="footer-item mb-15">联系邮箱：support@biaomengz.com</div>
            <div className="footer-item">地址：陕西省西安市高新区科技六路西段88号新加坡腾飞科汇城6层B区-125</div>
        </div>
    )
}

export default Footer;