import './index.css'
import Header from '../../components/Header/index'
import Footer from '../../components/Footer/index'

// 首页 
const Home = () => {
    return (
        <div className='home-box'>
            <Header />
            <div className='content justify-center'>
                <div className='home-content'></div>
            </div>
            <Footer />
        </div>
    )
}

export default Home;