import './App.css';
import React from 'react';
import Home from './pages/home/index'
import UserProtocol from './pages/user-protocol/index'
import PrivacyProtocol from './pages/privacy-protocol/index'
import { Routes, Route } from "react-router-dom"

//localStorage.setItem('recordIndex',0)

function App() {
  return (
    <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/user" element={<UserProtocol />}/>
        <Route path="/privacy" element={<PrivacyProtocol />}/>
    </Routes>
  );
}

export default App;
