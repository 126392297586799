import './index.css'
import { useState} from 'react'
import { useNavigate,NavLink } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const [data] = useState([{name: '企业文化',path: '/'},{name: '用户协议',path: '/user'},{name: '隐私协议',path: '/privacy'}])
    //const [recordIndex,setRecordIndex] = useState(0)
    
    const handleClick = (item,index) => {
        //localStorage.setItem('recordIndex',index)
        //setRecordIndex(index)
        if(item.name === '企业文化') {
            navigate('/');
        }
        if(item.name === '用户协议') {
            navigate('/user');
        }
        if(item.name === '隐私协议') {
            navigate('/privacy');
        }
    }
    return (
        <div>
            <div className='header'>
                <div className='flex-end'>
                    <div className='tag-box flex flex-end'>
                        {
                            data.map((item,index)=>{
                                return (
                                    <NavLink className='tag-item' key={index} onClick={()=>handleClick(item,index)} to={item.path}>
                                        {
                                            (currentItem)=>{
                                                console.log('currentItem',currentItem)
                                                return (
                                                    <>
                                                         <div style={currentItem.isActive ? {color: '#0084FF'}:{color:'#333'}}>{ item.name}</div>
                                                         <div className='item-line' style={currentItem.isActive ? {background: '#0084FF'}:{background:'#333'}}></div>
                                                    </>
                                                )
                                            }
                                        }
                                        {/* <div style={localStorage.getItem('recordIndex')==index ? {color: '#0084FF'}:{color:'#333'}}>{ item.name}</div>
                                        <div className='item-line' style={localStorage.getItem('recordIndex')==index ? {background: '#0084FF'}:{background:'#333'}}></div> */}
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;